var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "question-cards",
      class: {
        "question-cards--mobile": _vm.mobile,
        "question-cards--enlarged": _vm.enlargedUI,
      },
    },
    [
      _c("SlideXTransition", { attrs: { direction: _vm.direction } }, [
        _c(
          "div",
          { key: _vm.questionIndex, staticClass: "question-cards__wrapper" },
          [
            _c(
              "FlipHorizontalTransition",
              [
                _vm.isHost || _vm.isScribe || _vm.isStepSubmitted
                  ? _c(
                      "MissionCard",
                      { key: "question", staticClass: "hey-hoo" },
                      [
                        _c(
                          "div",
                          { staticClass: "question-cards__text" },
                          [
                            _vm.textVisible
                              ? _c("ResizableText", {
                                  key: _vm.questionIndex,
                                  attrs: {
                                    message: _vm.currentText,
                                    delay: 0.2,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "SlideFadeTransition",
                          [
                            !_vm.isStepSubmitted
                              ? _c(
                                  "MissionStatus",
                                  { staticClass: "my-3", attrs: { show: "" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.isHost || _vm.isScribe
                                            ? "Pick a question to answer"
                                            : "Waiting on Scribe..."
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("SlideFadeTransition", [
                          (_vm.isHost || _vm.isScribe) && !_vm.isStepSubmitted
                            ? _c(
                                "div",
                                { staticClass: "question-cards__actions" },
                                [
                                  _c(
                                    "RtbButton",
                                    {
                                      class: {
                                        "question-cards__action--hidden":
                                          _vm.isPrevDisabled,
                                      },
                                      attrs: { variant: "icon", color: "grey" },
                                      on: { click: _vm.prevQuestion },
                                    },
                                    [
                                      _c("SvgIcon", {
                                        attrs: { name: "chevron-left-regular" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "WeveButton",
                                    {
                                      staticClass: "mx-4",
                                      attrs: { variant: "primary" },
                                      on: { click: _vm.choose },
                                    },
                                    [_vm._v(" Choose ")]
                                  ),
                                  _c(
                                    "RtbButton",
                                    {
                                      class: {
                                        "question-cards__action--hidden":
                                          _vm.isNextDisabled,
                                      },
                                      attrs: { variant: "icon", color: "grey" },
                                      on: { click: _vm.nextQuestion },
                                    },
                                    [
                                      _c("SvgIcon", {
                                        attrs: {
                                          name: "chevron-right-regular",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    )
                  : _c("MissionCard", {
                      key: "hidden",
                      staticClass: "question-cards__dummy",
                    }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }