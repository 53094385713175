







































































import _ from "lodash"
import { db } from "@/firebase"
import {
  computed,
  defineComponent,
  ref,
  watch,
  onMounted
} from "@vue/composition-api"

import SlideXTransition from "@/components/GroupTeams/Common/Games/GameCardParts/SlideXTransition.vue"
import FlipHorizontalTransition from "@/components/GroupTeams/Common/Games/GameCardParts/FlipHorizontalTransition.vue"
import MissionCard from "@/components/GroupTeams/Common/Games/GameCardParts/MissionCard.vue"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import MissionStatus from "@/components/GroupTeams/Common/Games/GameCardParts/MissionStatus.vue"
import SlideFadeTransition from "@/components/GroupTeams/Common/Games/GameCardParts/SlideFadeTransition.vue"

import { createPlay } from "@/mixins/Game.js"

import { RtbButton } from "@/components/ui"
import { WeveButton } from "@weve/ui"
import useStore from "@/use/useStore"
import useQuestionCardsRef from "@/components/GroupTeams/Common/Games/QuestionCards/useQuestionCardsRef"
import User from "@shared/User"
import useEnlargedUI from "@/use/useEnlargedUI"

const SIZE_OF_QUESTION_SET = 3

export default defineComponent({
  name: "QuestionCards",
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SlideXTransition,
    FlipHorizontalTransition,
    MissionCard,
    WeveButton,
    ResizableText,
    RtbButton,
    MissionStatus,
    SlideFadeTransition
  },
  setup() {
    const { store } = useStore()
    const { databaseRef, mission } = useQuestionCardsRef()
    const direction = ref("left")

    const { enlargedUI } = useEnlargedUI()

    const game = computed(() => store.getters.game)

    const scribe = computed(() =>
      store.getters["GameUsers/usersOnlineArray"].find(User.isScribe)
    )
    const isHost = computed(() => store.getters["auth/isHost"])
    const viewer = computed(() =>
      isHost.value ? scribe.value : store.getters["auth/user"]
    )
    const isScribe = computed(() => store.getters.isScribe)

    const missionPlays = computed(() => store.getters.missionPlays)

    const missionStatus = ref({
      step: 0,
      index: 0
    })

    const questionIndex = computed(() => missionStatus.value?.index || 0)
    const missionStep = computed(() => missionStatus.value?.step || 0)

    const isStepSubmitted = computed(
      () => missionPlays.value.length > missionStep.value
    )

    const questions = computed(() =>
      mission.value?.instructions
        .split(";")
        .map(item => item.trim())
        .filter(Boolean)
    )

    const chosenQuestions = computed(() =>
      missionPlays.value.map(item => item.correct)
    )

    const setOfQuestions = computed(() => {
      const from = missionStep.value * SIZE_OF_QUESTION_SET
      const to = from + questions.value.length
      const sortPattern = mission.value.instructions
        .replace(/\s/g)
        .split("")
        .slice(from, to)

      return _.chain(questions.value)
        .reduce((acc, item, index) => {
          if (!chosenQuestions.value.includes(item)) {
            acc.push({ item, order: sortPattern[index] })
          }
          return acc
        }, [])
        .sortBy("order")
        .take(SIZE_OF_QUESTION_SET)
        .value()
    })

    const currentText = computed(() => {
      const item = setOfQuestions.value[questionIndex.value]?.item

      if (isStepSubmitted.value) {
        return chosenQuestions.value[missionStep.value]
      } else {
        return isHost.value || isScribe.value ? item : item.replace(/\S/g, "-")
      }
    })

    const isPrevDisabled = computed(() => questionIndex.value === 0)

    const isNextDisabled = computed(
      () => questionIndex.value === setOfQuestions.value.length - 1
    )

    const contentVisible = computed(() => {
      if (isHost.value || isScribe.value || isStepSubmitted) {
        return true
      }

      return false
    })

    watch(
      databaseRef,
      (newRef, oldRef) => {
        if (oldRef !== null && oldRef !== undefined) {
          oldRef.off("value", onUpdate)
        }
        if (newRef !== null) {
          newRef.on("value", onUpdate)
        }
      },
      { immediate: true }
    )

    function onUpdate(snapshot) {
      missionStatus.value = snapshot.val() || {}
    }

    function nextQuestion() {
      direction.value = "left"
      databaseRef.value.update({ index: questionIndex.value + 1 })
    }

    function prevQuestion() {
      direction.value = "right"
      databaseRef.value.update({ index: questionIndex.value - 1 })
    }

    function choose() {
      let play = createPlay({
        user: viewer.value,
        mission: mission.value,
        answer: [currentText.value],
        game: game.value,
        teamID: viewer.value.teamID
      })

      play.correct = currentText.value
      play.show = false

      store.dispatch("addPlay", play)
    }

    const textVisible = ref(false)
    onMounted(() => {
      setTimeout(() => {
        textVisible.value = true
      }, 700)
    })

    return {
      questions,
      mission,
      textVisible,
      direction,
      contentVisible,
      isPrevDisabled,
      isNextDisabled,
      viewer,
      chosenQuestions,
      isStepSubmitted,
      isHost,
      isScribe,
      missionStep,
      missionPlays,
      questionIndex,
      choose,
      nextQuestion,
      prevQuestion,
      currentText,
      enlargedUI,
      setOfQuestions
    }
  }
})
