import { db } from "@/firebase"

import useStore from "@/use/useStore"
import { computed } from "@vue/composition-api"

export default function useQuestionCardsRef() {
  const { store } = useStore()
  const mission = computed(() => store.getters.getCurrentMission)
  const roomID = computed(() => store?.getters.game?.id)

  const databaseRef = computed(() => {
    return db
      .auxiliary()
      .ref(`_room/${roomID.value}/questionCards/${mission.value.id}`)
  })

  return {
    mission,
    databaseRef
  }
}
